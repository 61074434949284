import React from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap';
import trophy from '../../assets/images/trophy1.png';
import trophy1 from '../../assets/images/trophy.png';

const Prizzes = () => {
  return (
    <>
    <Container fluid className='hsr-prizzes mt-5 mb-5 pb-5'>
    <h1 className='hsr-heading'>Rewards & Prizes</h1>
    <Container>
    <Row>
      <Col md={4} lg={4} sm={12}>
        <Card className='hsr-prize-card mb-5 shadow-sm'>
          <Card.Header className='p-4 card-header'>
            <h3>Winner</h3>
            <div className='hsr-certificate'>Certificate</div>
          </Card.Header>
          <Card.Body className='p-3'>
            <img src={trophy} alt="Trophy" className='w-100 py-4 px-3' />
          </Card.Body>
          <Card.Footer className='card-footer p-3'>
            <h3>₹ 7,500*</h3>
          </Card.Footer>
        </Card>
      </Col>
      <Col md={4} lg={4} sm={12}>
        <Card className='hsr-prize-card mb-5 shadow-sm'>
          <Card.Header className='p-4 card-header'>
            <h3>First Runner Up</h3>
            <div className='hsr-certificate'>Certificate</div>
          </Card.Header>
          <Card.Body className='p-3'>
            <img src={trophy} alt="Trophy" className='w-100 py-4 px-3' />
          </Card.Body>
          <Card.Footer className='card-footer p-3'>
            <h3>₹ 5,000*</h3>
          </Card.Footer>
        </Card>
      </Col>
      <Col md={4} lg={4} sm={12}>
        <Card className='hsr-prize-card mb-5 shadow-sm'>
          <Card.Header className='p-4 card-header'>
            <h3>Top 3 Teams</h3>
            <div className='hsr-certificate'>Certificate</div>
          </Card.Header>
          <Card.Body className='p-3'>
            <img src={trophy} alt="Trophy" className='w-100 py-4 px-3' />
          </Card.Body>
          <Card.Footer className='card-footer p-3'>
            <h3>HSR Goodies</h3>
          </Card.Footer>
        </Card>
      </Col>
    </Row>
    <a className='hsr-link text-center d-block' href="#"><i>View All Rewards & Prizes</i></a>
    </Container>
    </Container>
    </>
  )
}

export default Prizzes
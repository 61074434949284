import React from 'react';
import Countdown from 'react-countdown';
import {Container} from 'react-bootstrap';

const Completionist = () => <span>You are good to go!</span>;

const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <Completionist />;
    } else {
      // Render a countdown
      return (
      <>
      <span className="hsr-count-box">
      <h1>{days}</h1>
        <span className="hsr-count-parameter">Days</span>
        </span>
        <span className="hsr-count-box">
        <h1>{hours}</h1>
        <span className="hsr-count-parameter">Hours</span>
        </span>
        <span className="hsr-count-box">
        <h1>{minutes}</h1>
        <span className="hsr-count-parameter">Minutes</span>
        </span>
        <span className="hsr-count-box">
        <h1>{seconds}</h1>
        <span className="hsr-count-parameter">Seconds</span>
        </span>
        </>
        );
    }
  };

const CoutDown = () => {
  return (
    <>
    <Container fluid className="hsr-countdown">
        <div className="hsr-countdown-area">
            <h1 className="text-center text-white">Meet Us on July 31, 2023</h1>
     <Countdown
    date={new Date(2023, 6, 31, 0, 0, 0, 0)}
    renderer={renderer}
  />
  </div>
  </Container>
    </>
  )
}

export default CoutDown
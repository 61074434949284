import React from 'react';
import './PageHeading.scss';
import { Container, Breadcrumb } from 'react-bootstrap';

const PageHeading = (props) => {
    const heroImageStyle = {
        width: '100%',
        height: '30vh',
        backgroundImage: `url(${props.bg})`,
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed'
    }
    return (
        <>
            <Container fluid style={heroImageStyle} className='hsr-top p-0'>
                <div className='hsr-overlay'>
                    <Container>
                        <h1>{props.title}</h1>
                        <p><i>{props.oneliner}</i></p>
                        <div className="d-flex justify-content-center">
                            <Breadcrumb className='text-center d-block mt-3'>
                                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                <Breadcrumb.Item active>{props.title}</Breadcrumb.Item>
                            </Breadcrumb>
                        </div>
                    </Container>
                </div>
            </Container>
        </>
    )
}

export default PageHeading
import React from 'react'
import './Prizes.scss';
import NavBar from '../../components/common/NavBar';
import Footer from '../../components/common/Footer';
import PageHeading from '../../components/pageHeading/PageHeading';
import bg from '../../assets/images/prize.jpg';
import { Container, Row, Col, Card } from 'react-bootstrap';
import trophy from '../../assets/images/trophy1.png';
import { Helmet } from 'react-helmet';

const Prizes = () => {
  return (
    <>
    <Helmet>
                <meta charSet="utf-8" />
                <title>Rewards & Prizes - HSR HackSprint</title>
                <meta name="title" content="Exciting Rewards and Prizes Await at HSR HackSprint | Engineering Student Hackathon" />
                <meta  name="description" content="Participate in HSR HackSprint, the premier hackathon for engineering students, and stand a chance to win amazing rewards and prizes. Showcase your skills, innovation, and teamwork for a shot at recognition and exciting incentives. Join us now!"/>
                <link rel="canonical" href="https://hacksprint.hsrsolutions.co.in/prizes" />
            </Helmet>
    <NavBar />
    <PageHeading bg={bg} title="Rewards & Prizes" oneliner="Compete, excel, and reap the rewards at HSR HackSprint"/>
    <Container className='mt-5 mb-5'>
      <h4 className='mt-5 mb-4'><b>What's at stake? - Rewards and Prizes:</b></h4>
      <p>Attractive prizes to be won along with the opportunity to get a chance for free Skill Development Program at HSR Hi-Tech Solutions (Worth ₹ 40,000) in various domains. Top performers might get a chance to be a part of the hiring process for Tech Internships(Monthly Stipend ₹ 5,000 - ₹ 10,000)</p>

      <ul>
        <li><b>Winners will get ₹ 7,500*</b></li>
        <li><b>First Runner Up will get ₹ 5,000*</b></li>
      </ul>

      <p className='mt-5'><b>Certificate of merit will be provided to the following:</b></p>
      <ul>
        <li>Top 10 teams will get Certificate of Merit</li>
        <li>Level 2 qualifying teams</li>
        <li>Level 1 teams scoring 60% or above marks</li>
      </ul>


    </Container>
    <Container>
    <Row>
      <Col md={4} lg={4} sm={12}>
        <Card className='hsr-prize-card mb-5 shadow-sm'>
          <Card.Header className='p-4 card-header'>
            <h3>Winner</h3>
            <div className='hsr-certificate'>Certificate</div>
          </Card.Header>
          <Card.Body className='p-3'>
            <img src={trophy} alt="Trophy" className='w-100 py-4 px-3' />
          </Card.Body>
          <Card.Footer className='card-footer p-3'>
            <h3>₹ 7,500*</h3>
          </Card.Footer>
        </Card>
      </Col>
      <Col md={4} lg={4} sm={12}>
        <Card className='hsr-prize-card mb-5 shadow-sm'>
          <Card.Header className='p-4 card-header'>
            <h3>First Runner Up</h3>
            <div className='hsr-certificate'>Certificate</div>
          </Card.Header>
          <Card.Body className='p-3'>
            <img src={trophy} alt="Trophy" className='w-100 py-4 px-3' />
          </Card.Body>
          <Card.Footer className='card-footer p-3'>
            <h3>₹ 5,000*</h3>
          </Card.Footer>
        </Card>
      </Col>
      <Col md={4} lg={4} sm={12}>
        <Card className='hsr-prize-card mb-5 shadow-sm'>
          <Card.Header className='p-4 card-header'>
            <h3>Top 3 Teams</h3>
            <div className='hsr-certificate'>Certificate</div>
          </Card.Header>
          <Card.Body className='p-3'>
            <img src={trophy} alt="Trophy" className='w-100 py-4 px-3' />
          </Card.Body>
          <Card.Footer className='card-footer p-3'>
            <h3>HSR Goodies</h3>
          </Card.Footer>
        </Card>
      </Col>
    </Row>
    <p className='text-center'><b>Participation Certificate will be awarded to every participants.</b></p>
    </Container>
    <Footer />
    </>
  )
}

export default Prizes
import React from 'react'
import NavBar from '../../components/common/NavBar';
import HeroImage from './HeroImage';
import './Home.scss';
import CountDown from './CoutDown';
import TimeLines from './TimeLines';
import EventDetails from './EventDetails';
import Prizzes from './Prizzes';
import About from './About';
import FAQ from './FAQ';
import Footer from '../../components/common/Footer';
import { Helmet } from 'react-helmet';

const Home = () => {
  return (
    <>
    <Helmet>
                <meta charSet="utf-8" />
                <title>Home - HSR HackSprint</title>
                <meta name="title" content="HSR HackSprint - Empowering Engineering Students through Innovation and Collaboration" />
                <meta  name="description" content="Join HSR HackSprint, the ultimate hackathon experience for engineering students in India. Unleash your potential, tackle real-world challenges, and showcase your skills in a collaborative and innovative environment. Participate, learn, and make a difference at HSR HackSprint."/>
                <link rel="canonical" href="https://hacksprint.hsrsolutions.co.in/" />
            </Helmet>
    <NavBar />
    <HeroImage />
    <CountDown />
    <TimeLines />
    <About />
    <EventDetails />
    <Prizzes />
    <FAQ />
    <Footer />
    </>

  )
}

export default Home;
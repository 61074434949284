import React from 'react'
import './About.scss'
import PageHeading from '../../components/pageHeading/PageHeading'
import bg from '../../assets/images/office.jpg'
import NavBar from '../../components/common/NavBar'
import Footer from '../../components/common/Footer'
import { Card, Col, Container, Row } from 'react-bootstrap'
import CountDown from '../home/CoutDown';
import { CalendarMonth, MicrosoftTeams, Motherboard, RocketTakeoff } from 'react-bootstrap-icons'
import { Helmet } from 'react-helmet'

const About = () => {
  return (
    <>
    <Helmet>
                <meta charSet="utf-8" />
                <title>About - HSR HackSprint</title>
                <meta name="title" content="About HSR HackSprint: Empowering Engineering Students through Innovation and Collaboration" />
                <meta  name="description" content="Discover HSR HackSprint, the premier hackathon for engineering students in India. Join us in fostering innovation, teamwork, and real-world problem-solving. Learn more about our mission and annual event dedicated to empowering the next generation of tech pioneers."/>
                <link rel="canonical" href="https://hacksprint.hsrsolutions.co.in/about" />
            </Helmet>

      <NavBar />
      <PageHeading bg={bg} title={"About Us"} oneliner={"Empowering engineering students through real-world projects and teamwork at HSR HackSprint."} />
      <Container className='mb-5 mt-5 hsr-about-content'>
        <p>
          HSR HackSprint is a flagship hackathon organized by <a href="https://www.hsrsolutions.co.in" rel="noreferrer" target='_blank'><b>HSR Hi-Tech Solutions</b></a> in association with <a href="https://hithaldia.ac.in/" rel="noreferrer" target='_blank'><b>Department of Computer Science & Engineering, Haldia Institute of Technology</b></a>, specifically designed for engineering students. Our mission is to provide a platform for students to gain practical experience, enhance their teamwork skills, and work on real-world projects. We understand that the transition from college to the professional world can be challenging, and we aim to bridge that gap by offering an exciting opportunity to showcase talent and learn valuable skills. With a vision to nurture innovation and collaboration, HSR Hack Sprint is an annual event organized around the 15th of August, coinciding with India's Independence Day. This date holds a special significance for us, as it symbolizes our commitment to breaking barriers and propelling our nation toward a brighter future.
          <br></br><br></br>
          HSR Hack Sprint is more than just a hackathon; it's an opportunity for participants to push the boundaries of their creativity and problem-solving skills. Whether you're a seasoned developer, a budding entrepreneur, or an enthusiastic student, this event welcomes individuals from all backgrounds to showcase their talents and contribute to the tech ecosystem.
          <br></br><br></br>
          HSR HackSprint is tailored for engineering students across India. We recognize the immense potential and talent that lies within this vibrant community. By focusing on engineering students, we aim to empower them with the necessary tools, experiences, and networking opportunities to thrive in their careers.
        </p>

      </Container>
      <CountDown />
      <Container fluid className='hsr-why-choose p-4'>
      <Container className='mt-5 mb-5'>
        <h1 className='mb-5'>Why Join Us?</h1>
        <Row>
          <Col md={3} lg={3} sm={3}>
            <Card className='shadow-sm p-3 hsr-why-card'>
              <RocketTakeoff className='hsr-icons' />
              <h3>Real-World Projects</h3>
              <p className='text-secondary'>HSR HackSprint provides a platform for students to tackle real-world problems through software or web development projects. It allows participants to apply their technical knowledge to create practical solutions, thereby gaining hands-on experience that goes beyond theoretical classroom learning.</p>
            </Card>
          </Col>
          <Col md={3} lg={3} sm={3}>
            <Card className='shadow-sm p-3 hsr-why-card'>
              <MicrosoftTeams className='hsr-icons' />
              <h3>Teamwork & Collaboration</h3>
              <p className='text-secondary'>We understand the importance of teamwork and collaboration in today's professional landscape. HSR HackSprint encourages students to work in teams, fostering effective communication, coordination, and cooperation. It's an opportunity to experience the dynamics of team-based projects.</p>
            </Card>
          </Col>
          <Col md={3} lg={3} sm={3}>
            <Card className='shadow-sm p-3 hsr-why-card'>
              <Motherboard className='hsr-icons' />
              <h3>Skill Development</h3>
              <p className='text-secondary'>Alongside the hackathon, we offer workshops and mentorship sessions led by industry experts. These sessions aim to enhance students' skills in areas such as problem-solving, coding, project management, and presentation skills. We believe in equipping students with the necessary tools to excel in all areas.</p>
            </Card>
          </Col>
          <Col md={3} lg={3} sm={3}>
            <Card className='shadow-sm p-3 hsr-why-card'>
              <CalendarMonth className='hsr-icons' />
              <h3>Annual Event</h3>
              <p className='text-secondary'>HSR HackSprint is an annual event that takes place before 15th August, providing a consistent platform for students to participate and grow year after year. Each edition will bring new challenges and opportunities, allowing participants to build upon their previous experiences and showcase their progress.</p>
            </Card>
          </Col>
        </Row>
      </Container>
      </Container>
      <Footer />
    </>
  )
}

export default About
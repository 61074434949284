import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

const EventDetails = () => {
  return (
    <>
    <Container className='mb-5 mt-5 hsr-details'>
    <Row className='p-3 mb-4'>
                    <Col md={3} lg={3} sm={12} className='mb-3'>
                        <h3>Event Details</h3>

                    </Col>
                    <Col md={6} lg={6} sm={12}  className='mb-3' >
                        <p>This conference run through all 4 days from 23 - 26 May 2016.
                            We also provide free lunch and coffee break in each day.</p>
                    </Col>
                    <Col md={3} lg={3} sm={12}  className='mb-3'>
                        <a href='/hackathon-details' className='ms-auto d-block'><i>View Full Details</i></a>
                    </Col>
                </Row>

                <div className='hsr-event-details'>
                <h1>All that you need to know about HSR HackSprint 1.0 - Website Development Challenge</h1>
<span className='hsr-gap'><br></br></span>
<p className='mt-1 mb-1'>
<b>HackSprint</b> is HSR’s Flagship Engineering Campus Challenge which provides you with the opportunity to apply your technical knowledge and skills, to compete and complete key challenges.<br></br>
<br></br>
In its first edition, <b>HSR HackSprint</b> bringing real-world problem statements from the common challenges in a big college/university campus to the brightest engineering minds across the globe!
<br></br>
<br></br>
So are you ready to enter the HackSprint?
<br></br><br></br>
</p>
<p className='mb-1'><b className='d-block'>Eligibility & Team Rules:</b></p>
<div className='hsr-eligibility pt-3'>
<ul className='pt-4'>
    <li className='mt-0'><span>This competition is open to students pursuing B.Tech/B.E./B.S./M.Tech/M.S or related Engineering Programs across ALL Engineering Colleges in India (Batch 2024, 2025, 2026 & 2027)</span></li>
    <li><span>Students must register in teams of 3 to 5 members</span></li>
    <li><span>The batch of 2023 is not eligible to participate in this competitions</span></li>
    <li><span>The participating students can be from any specialization</span></li>
    <li><span>They should be full-time students from the same engineering college</span></li>
    <li><span>One person cannot be a member of more than one team</span></li>
    <li><span>Any deviation from the above will result in immediate disqualification of the entire team</span></li>
</ul>
</div>

                </div>

    </Container>
    </>
  )
}

export default EventDetails
import React from 'react'
import './Hackathon.scss';
import NavBar from '../../components/common/NavBar';
import Footer from '../../components/common/Footer';
import PageHeading from '../../components/pageHeading/PageHeading';
import bg from '../../assets/images/event.jpg';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

const Hackathon = () => {
    return (
        <>
        <Helmet>
                <meta charSet="utf-8" />
                <title>Hackathon Details - HSR HackSprint</title>
                <meta name="title" content="HSR HackSprint: Empowering Engineering Students through Innovation and Collaboration" />
                <meta  name="description" content="Join HSR HackSprint, the ultimate platform for engineering students in India, to tackle real-world challenges, showcase your skills, and collaborate with like-minded peers. Unleash your potential at this annual hackathon event and be part of a transformative journey towards innovation and impact."/>
                <link rel="canonical" href="https://hacksprint.hsrsolutions.co.in/hackathon-details" />
            </Helmet>
            <NavBar />
            <PageHeading bg={bg} title={'Hackathon Details'} oneliner={"Unleash your potential and tackle real-world challenges at HSR HackSprint."} />

            <Container className='mb-5 mt-5 hsr-details'>


                <div className='hsr-event-details'>
                    <h1>All that you need to know about HSR HackSprint 1.0 - Website Development Challenge</h1>
                    <span className='hsr-gap'><br></br></span>
                    <p className='mt-1 mb-1'>
                        <b>HackSprint</b> is HSR’s Flagship Engineering Campus Challenge which provides you with the opportunity to apply your technical knowledge and skills, to compete and complete key challenges.<br></br>
                        <br></br>
                        In its first edition, <b>HSR HackSprint</b> bringing real-world problem statements from the common challenges in a big college/university campus to the brightest engineering minds across the country!
                        <br></br>
                        <br></br>
                        So are you ready to enter the HackSprint?
                        <br></br><br></br>
                    </p>
                    <p className='mb-1'><b className='d-block'>Eligibility & Team Rules:</b></p>
                    <div className='hsr-eligibility pt-3'>
                        <ul className='pt-4 mb-0'>
                            <li className='mt-0'><span>This competition is open to students pursuing B.Tech/B.E./B.S./M.Tech/M.S or related Engineering Programs across ALL Engineering Colleges in India (Batch 2024, 2025, 2026 & 2027)</span></li>
                            <li><span>Students must register in teams of 3 to 5 members</span></li>
                            <li><span>The batch of 2023 is not eligible to participate in this competitions</span></li>
                            <li><span>The participating students can be from any specialization</span></li>
                            <li><span>They should be full-time students from the same engineering college</span></li>
                            <li><span>One person cannot be a member of more than one team</span></li>
                            <li><span>Any deviation from the above will result in immediate disqualification of the entire team</span></li>
                        </ul>
                    </div>
                    
                    <p className='mb-1 pt-4'><b className='d-block'>Stages:</b></p>
                    <p className='pt-2'>The Software Development Track will have 3 elimination levels to test your technical, analytical, and ideation skills before the National Finale where the Finalists will get to present their solutions to the Panel of Domain Experts at Flipkart.</p>
                    <p className='mb-1 pt-4'><b className='d-block'>Level 1: Website Development & Tech Quiz:</b></p>
                    <div className='hsr-eligibility pt-3'>
                        <ul className='pt-4 mb-0'>
                            <li className='mt-0'><span>All registered teams will have to take an online quiz individually.</span></li>
                            <li><span>It is mandatory for all team members to attempt the quiz round</span></li>
                            <li><span>A team will be scored based on the average marks of the team members attempting the quiz round</span></li>
                            <li><span>All teams will be taking this quiz simultaneously during the quiz window</span></li>
                            <li><span>Every participant will be ranked on Accuracy + Speed (Time Taken) + Start Time i.e., if two teams have the same score, then the team that started and finished earlier will be ranked higher</span></li>
                            <li><span>Participating teams will move on to the next level based on their performance in this assessment. This is not an elimination round</span></li>
                            <li><span>It will be based on Website Development and Tech (Technology Awareness + Techstack & Computer Science Fundamentals)</span></li>
                            
                        </ul>
                    </div>
                    
                    <p className='mb-1 pt-4'><b className='d-block'>Level 2: HackSprint Round:</b></p>
                    <div className='hsr-eligibility pt-3'>
                        <ul className='pt-4 mb-0'>
                            <li className='mt-0'><span>All teams participated in level 1 will have to participate in level 2</span></li>
                            <li><span>Teams will have access to only one problem statement that has been allocated to them</span></li>
                            <li><span>You'll be able to make submissions until the deadline for this level anytime during the challenge window</span></li>
                            <li><span>All teams will have to submit a recorded video of their submission explaining about final product they will develop</span></li>
                            <li><span>The expectation in this level will be to submit the functional product along with the demo video and code submission</span></li>
                            <li><span>Participating teams will move on to the next level based on their performance in this assessment. This is an elimination round</span></li>
                            
                        </ul>
                    </div>

                    <p className='mb-1 pt-4'><b className='d-block'>Level 3: The Finale:</b></p>
                    <div className='hsr-eligibility pt-3'>
                        <ul className='pt-4 mb-0'>
                            <li className='mt-0'><span>In the final level of the hackathon, finalists will be invited to showcase their solutions to the panel of domain experts at judges.</span></li>
                            <li><span>Your skills will be tested on the basis of quality of code, final product and your presentation skills. </span></li>
                            <li><span>You'll be asked few questions by our judges to test your level.</span></li>
                            
                        </ul>
                    </div>

                </div>

            </Container>
            <Footer />
        </>
    )
}

export default Hackathon
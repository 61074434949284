import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/home/Home';
import './Global.scss';
import Register from './pages/register/Register';
import About from './pages/about/About';
import Hackathon from './pages/hackathon/Hackathon';
import Timelines from './pages/timelines/Timelines';
import Prizes from './pages/prizes/Prizes';
import Privacy from './pages/privacy/Privacy';
import Terms from './pages/terms/Terms';
import Faq from './pages/faq/Faq';
function App() {
  document.addEventListener('contextmenu', (e) => e.preventDefault());

function ctrlShiftKey(e, keyCode) {
  return e.ctrlKey && e.shiftKey && e.keyCode === keyCode.charCodeAt(0);
}

document.onkeydown = (e) => {
  // Disable F12, Ctrl + Shift + I, Ctrl + Shift + J, Ctrl + U
  if (
    e.keyCode === 123 ||
    ctrlShiftKey(e, 'I') ||
    ctrlShiftKey(e, 'J') ||
    ctrlShiftKey(e, 'C') ||
    (e.ctrlKey && e.keyCode === 'U'.charCodeAt(0))
  )
    return false;
};
  return (
    <>
      <Router>
        <Routes>
          <Route path='/' exact element={<Home />}/>
          <Route path='/register' exact element={<Register/>}/>
          <Route path='/about' exact element={<About />} />
          <Route path='/hackathon-details' exact element={<Hackathon />}/>
          <Route path="/timelines" exact element={<Timelines />} />
          <Route path='/prizes' exact element={<Prizes />} />
          <Route path='/privacy-policy' exact element={<Privacy />} />
          <Route path="/terms-conditions" exact element={<Terms />} />
          <Route path='/faqs' exact element={<Faq />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;

import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import './Footer.scss';
import { Whatsapp, PhoneFill, EnvelopeFill, Facebook, Instagram, Twitter, Youtube, Linkedin } from 'react-bootstrap-icons';

const Footer = () => {
  return (
    <>
    <Container fluid className='hsr-footer p-0'>
        <Container className='pt-5 pb-4 px-5 mt-5'>
            <Row className='g-5'>
                <Col md={3} lg={3} sm={12}>
                    <h4>About Us</h4>
                    <p>HSR HackSprint 2023 is a flagship hackathon by HSR Hi-Tech Solutions. It's the perfect opportunity for engineering students to get real-world experience, build a team, and put their skills to the test.</p>
                </Col>

                <Col md={3} lg={3} sm={6}>
                    <h4>Important Links</h4>
                    <ul>
                    <li><a href="/timelines">Timelines</a></li>
                        <li><a href="/about">About Us</a></li>
                        <li><a href="/hackathon-details">Hackathon Details</a></li>
                        <li><a href="/prizes">Rewards & Prizes</a></li>
                        <li><a href="/register">Register</a></li>
                    </ul>
                </Col>
                <Col md={3} lg={3} sm={6}>
                    <h4>Help</h4>
                    <ul>
                    <li><a href="/faqs">FAQs</a></li>
                        <li><a href="/terms-conditions">Terms & Condition</a></li>
                        <li><a href="/privacy-policy">Privacy Policy</a></li>
                        {/* <li><a href="/help-center">Help Centre</a></li>
                        <li><a href="/sitemap">Sitemap</a></li> */}
                    </ul>
                </Col>
                <Col md={3} lg={3} sm={12}>
                    <h4>Contact Us</h4>
                    <ul>
                    <li><a href="https://wa.me/+919102538091" target="_blank" rel="noreferrer"><Whatsapp className='me-2' /> +91 9102538091</a></li>
                        <li><a href="tel:+919102538091" rel="noreferrer" target='_blank'><PhoneFill className='me-2' />+91 9102538091</a></li>
                        <li><a href="mailto:info@hsrsolutions.co.in" rel="noreferrer" target="_blank"><EnvelopeFill className='me-2'/> info@hsrsolutions.co.in</a></li>
                        <li className='mt-4'>Follow Us:</li>
                        <li><a href="https://www.facebook.com/hsrsolutions/" rel="noreferrer" target='_blank'><Facebook className='me-2'/></a> <a href="https://www.instagram.com/hsrsolutions/?hl=en" target='_blank' rel="noreferrer"><Instagram className='me-2'/></a> <a href="https://twitter.com/hsrsolutions" rel="noreferrer" target='_blank'><Twitter className='me-2'/></a> <a href="https://in.linkedin.com/company/hsrsolutions" rel="noreferrer" target="_blank"><Linkedin className='me-2'/></a> <a href="https://www.youtube.com/channel/UChxQ4IGJd98SZhcYTba16ng" rel="noreferrer" target='_blank'><Youtube/></a> </li>
                    </ul>
                </Col>
            </Row>
        </Container>
        <div className='hsr-copyright'>
           <span className='d-block text-center'>© Copyright <a href="https://www.hsrsolutions.co.in" target='_blank' rel="noreferrer">HSR Hi-Tech Solutions</a>, All Right Reserved</span> 
        </div>
    </Container>
    </>
  )
}

export default Footer
import React,{useState} from 'react';
import './NavBar.scss';
import { Container, Navbar, Nav, Offcanvas } from 'react-bootstrap';
import { List } from 'react-bootstrap-icons';

const NavBar = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  return (
    <>
    <Navbar expand="lg" className="hsr-navbar fixed-top bg-white shadow-sm" >
        <Container >
        <Navbar.Brand href="/">
            <span>HSR HackSprint</span>
        </Navbar.Brand>
        <button className='d-lg-none d-sm-block hsr-toggle' onClick={handleShow}>
            <List />
        </button>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link href="/timelines">Timelines</Nav.Link>
            <Nav.Link href="/about">About Us</Nav.Link>
            <Nav.Link href="/hackathon-details">Hackathon Details</Nav.Link>
            <Nav.Link href="/prizes">Rewards & Prizes</Nav.Link>
            {/* <Nav.Link href="/contact">Contact Us</Nav.Link> */}
            <Nav.Link href="/register">Register</Nav.Link>
          </Nav>
        </Navbar.Collapse>
        </Container>
    </Navbar>

    <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton variant="white">
        <Navbar.Brand href="/">
            <span>HSR HackSprint</span>
        </Navbar.Brand>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ul>
            <li><a href='/' className='nav-link'>Home</a></li>
            <li><a href='/timelines' className='nav-link'>Timelines</a></li>
            <li><a href='/about' className='nav-link'>About Us</a></li>
            <li><a href='/hackathon-details' className='nav-link'>Hackathon Details</a></li>
            <li><a href='/prizes' className='nav-link'>Rewards & Prizes</a></li>
            {/* <li><a href='/contact' className='nav-link'>Contact Us</a></li> */}
            <li><a href='/register' className='nav-link'>Register</a></li>
          </ul>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  )
}

export default NavBar
import React, { useState } from 'react'
import { Container, Row, Col, Form, InputGroup, Alert } from 'react-bootstrap'
import { useForm } from 'react-hook-form';
import { db } from '../../firebase/Firebase';
import { setDoc, query, getDocs, doc, collection, where, updateDoc } from 'firebase/firestore';

const IndividualForm = () => {

    // Forms hooks
    const [show, setShow] = useState(true);
    const [error, setError] = useState('');
    const { register, handleSubmit } = useForm();
    const [success, setSuccess] = useState(false);
    const [user, setUser] = useState([]);

    const loadscript = (src) => {
        return new Promise((resolve) => {
            const script = document.createElement('script');
            script.src = src

            script.onload = () => {
                resolve(true)
            }

            script.onerror = () => {
                resolve(false)
            }

            document.body.appendChild(script);
        })
    }

    // Razorpay
    const initiatePayment = async (data) => {
        const res = await loadscript('https://checkout.razorpay.com/v1/checkout.js');
        if (!res) {
            setError('We are unable to upload payment gateway. Please try again!')
            return
        }

        const options = {
            key: process.env.REACT_APP_RAZORPAY,
            currency: "INR",
            amount: process.env.REACT_APP_PAYMENT,
            name: "HSR HackSprint",
            description: "A Hackathon By HSR Hi-Tech Solutions.",
            handler: async function (response) {
                try {

                    // console.log(register)
                    const userDetailsRef = doc(db, "participants", data.email);
                    await updateDoc(userDetailsRef, {
                        payment_id: response.razorpay_payment_id,
                        payment_status: "Paid"
                    })
                    setSuccess(true);
                    setUser([
                        {
                            name: data.fullName,
                            email: data.email,
                            payment_id: response.razorpay_payment_id
                        }
                    ])
                } catch (er) {
                    console.log(er)
                    alert("Some error occured. Try again!")
                }
            }
        }

        const paymentObject = new window.Razorpay(options);
        paymentObject.open()
    }


    const onSubmit = async (data) => {
        console.log(process.env.REACT_APP_RAZORPAY)
        try {
            const q = query(collection(db, "c"), where("email", "==", data.email));
            const docs = await getDocs(q);
            if (docs.docs.length === 0) {
                await setDoc(doc(db, "participants", data.email), data);
                initiatePayment(data)
            } else {
                setError('You have already registered!')
            }
        } catch (err) {
            setError('There is some technical error from our side. Please try again!')
        }

    };



    let stateList = ["Andhra Pradesh",
        "Arunachal Pradesh",
        "Assam",
        "Bihar",
        "Chhattisgarh",
        "Goa",
        "Gujarat",
        "Haryana",
        "Himachal Pradesh",
        "Jammu and Kashmir",
        "Jharkhand",
        "Karnataka",
        "Kerala",
        "Madhya Pradesh",
        "Maharashtra",
        "Manipur",
        "Meghalaya",
        "Mizoram",
        "Nagaland",
        "Odisha",
        "Punjab",
        "Rajasthan",
        "Sikkim",
        "Tamil Nadu",
        "Telangana",
        "Tripura",
        "Uttarakhand",
        "Uttar Pradesh",
        "West Bengal",
        "Andaman and Nicobar Islands",
        "Chandigarh",
        "Dadra and Nagar Haveli",
        "Daman and Diu",
        "Delhi",
        "Lakshadweep",
        "Puducherry"]
    return (
        <>
            <Container fluid>
                {!success ?
                <form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Col md={6} lg={6} sm={12}>
                        <Form.Group className="mb-4" >
                            <Form.Label>Full Name<span className='text-danger'>*</span></Form.Label>
                            <Form.Control type="text" {...register('fullName')} required />
                        </Form.Group>
                    </Col>
                    <Col md={6} lg={6} sm={12}>
                        <Form.Group className="mb-4" >
                            <Form.Label>Email Address<span className='text-danger'>*</span></Form.Label>
                            <Form.Control type="email" {...register('email')} required />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={6} lg={6} sm={12}>
                        <Form.Group className="mb-4" >
                            <Form.Label>Mobile Number<span className='text-danger'>*</span></Form.Label>
                            <InputGroup className="mb-3">
                                <InputGroup.Text >+91</InputGroup.Text>
                                <Form.Control type="tel" minLength={10} maxLength={10} {...register('mobile')} required
                                />
                            </InputGroup>
                        </Form.Group>
                    </Col>
                    <Col md={6} lg={6} sm={12}>
                        <Form.Group className="mb-4" >
                            <Form.Label>Gender<span className='text-danger'>*</span></Form.Label>
                            <br></br>
                            <Form.Check
                                inline
                                label="Male"
                                type='radio'
                                name="gender"
                                className='mt-2'
                                value="Male"
                                {...register('gender')}
                                required
                            />
                            <Form.Check
                                inline
                                label="Female"
                                type='radio'
                                name="gender"
                                className='mt-2'
                                value="Female"
                                {...register('gender')}
                                required
                            />
                            <Form.Check
                                inline
                                label="Others"
                                type='radio'
                                name="gender"
                                className='mt-2'
                                value="Others"
                                {...register('gender')}
                                required
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={6} lg={6} sm={12}>
                        <Form.Group className="mb-4" >
                            <Form.Label>College Name<span className='text-danger'>*</span></Form.Label>
                            <Form.Control type="text" {...register('collegeName')} required />
                        </Form.Group>
                    </Col>
                    <Col md={6} lg={6} sm={12}>
                        <Form.Group className="mb-4" >
                            <Form.Label>Degree<span className='text-danger'>*</span></Form.Label>
                            <Form.Select {...register('degree')} defaultValue={'Select Your Degree'} required >
                                <option disabled>Select Your Degree</option>
                                <option value="B.E">B.E</option>
                                <option value="B.Tech">B.Tech</option>
                                <option value="M.Tech">M.Tech</option>
                                <option value="M.Sc">M.Sc</option>
                                <option value="B.Sc">B.Sc</option>
                                <option value="Diploma">Diploma</option>

                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={6} lg={6} sm={12}>
                        <Form.Group className="mb-4" >
                            <Form.Label>Branch/Stream<span className='text-danger'>*</span></Form.Label>
                            <Form.Control type="text" {...register('branch')} required />
                        </Form.Group>
                    </Col>
                    <Col md={6} lg={6} sm={12}>
                        <Form.Group className="mb-4" >
                            <Form.Label>University Roll Number<span className='text-danger'>*</span></Form.Label>
                            <Form.Control type="text" {...register('rollNo')} required />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={6} lg={6} sm={12}>
                        <Form.Group className="mb-4" >
                            <Form.Label>City<span className='text-danger'>*</span></Form.Label>
                            <Form.Control type="text" {...register('city')} required />
                        </Form.Group>
                    </Col>
                    <Col md={6} lg={6} sm={12}>
                        <Form.Group className="mb-4" >
                            <Form.Label>State<span className='text-danger'>*</span></Form.Label>
                            <Form.Select {...register('state')} defaultValue={'Select Your State'} required >
                                <option disabled>Select Your State</option>
                                {stateList.map((s) => (
                                    <option key={s}>{s}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>

                    <Col md={6} lg={6} sm={12}>
                    <Form.Group className="mb-4">
                            <Form.Label>Course Started in</Form.Label>
                            <Form.Select {...register('courseStart')} defaultValue={'Select Year'}>
                                <option disabled>Select Year</option>
                                <option>2020</option>
                                <option>2021</option>
                                <option>2022</option>
                                <option>2023</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col md={6} lg={6} sm={12}>
                        <Form.Group className="mb-4">
                            <Form.Label>Course Completion Expected in</Form.Label>
                            <Form.Select {...register('courseCompletion')} defaultValue={'Select Year'}>
                                <option disabled>Select Year</option>
                                <option>2024</option>
                                <option>2025</option>
                                <option>2026</option>
                                <option>2027</option>
                                <option>2028</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>

                    <Col md={6} lg={6} sm={12}>
                        <Form.Group className="mb-4" >
                            <Form.Label>How you get to know about this hackathon?</Form.Label>
                            <Form.Select {...register('medium')} defaultValue={'Select Medium'}>
                                <option disabled>Select Medium</option>
                                <option>Google</option>
                                <option>Facebook</option>
                                <option>Instagram</option>
                                <option>Linkedin</option>
                                <option>Youtube</option>
                                <option>WhatsApp</option>
                                <option>Friends</option>
                                <option>Campus Ambassadors</option>
                                <option>Teachers</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>
                <span style={{color: 'gray', fontSize:"0.9rem", marginTop:"-1rem", marginBottom:"1rem"}}>By clicking on register button you agree to our <a href="/terms-conditions">Terms & Conditions</a> for HackSprint.</span>
                <button type="submit" className='hsr-theme-button d-block mt-4 m-auto'>Register</button>
            </form>
                :
                <Container>
                    <p className='text-success'><b>Congratulations! Your payment for the hackathon has been confirmed.</b></p>
                    <p><b>Name: </b>{user[0].name}</p>
                    <p><b>Email: </b>{user[0].email}</p>
                    <p><b>Payment Id: </b>{user[0].payment_id}</p>
                    

<p>Your registration is now complete. You will receive an email with your confirmation details shortly.</p>

<p><b>Next Steps:</b></p>
<ul>
    <li>Take a screenshot of confirmation page for future reference.</li>
    <li>Register your group with your teammates on Group Registration Section.</li>
    <li>Join our WhatsApp group for further communication. <a href="https://chat.whatsapp.com/FcdXNoAyruS0IiazZlIlh5" target="_blank" rel='noreferrer'>Click here</a></li>
    <li>Wait for your confirmation email which will have the enrollment ID which will be required for the group registration.</li>
    <li>Please go through the hackathon details consisting the rules for this hackathon.</li>
</ul>
<p><b>We look forward to seeing you at the hackathon!</b></p>
<p>Once you will refresh the page these details will be gone.</p>
                </Container>
                }
                {error ? <Alert variant="danger" onClose={() => setShow(false)} dismissible>
                    <p>
                        {error}
                    </p>
                </Alert> : ''}
            </Container>
        </>
    )
}

export default IndividualForm
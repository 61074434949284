import React from 'react'
import { Container, Tabs, Tab } from 'react-bootstrap'
import NavBar from '../../components/common/NavBar'
import Footer from '../../components/common/Footer'
import './Register.scss';
import PageHeading from '../../components/pageHeading/PageHeading';
import bg from '../../assets/images/register.jpg';
import IndividualForm from './IndividualForm';
import { Helmet } from 'react-helmet';

const Register = () => {
    
  return (
    <>
    <Helmet>
                <meta charSet="utf-8" />
                <title>Register - HSR HackSprint</title>
                <meta name="title" content="Register Now for HSR HackSprint - Empowering Engineering Students to Innovate and Excel" />
                <meta  name="description" content="Join HSR HackSprint, the ultimate hackathon for engineering students in India. Register now to showcase your skills, solve real-world challenges, and compete for exciting prizes. Don't miss this opportunity to collaborate, learn, and unleash your potential."/>
                <link rel="canonical" href="https://hacksprint.hsrsolutions.co.in/register" />
            </Helmet>
    <NavBar />
    <PageHeading bg={bg} title={"Register"} oneliner={"Don't miss your chance to hack with the best and brightest minds in tech."} />
    <Container className='mt-5 mb-5 py-5 hsr-registration'>
    <Tabs
      defaultActiveKey="register"
      fill
    >
      <Tab eventKey="register" title="Individual Registration">
        <IndividualForm />
      </Tab>
      <Tab eventKey="group" title="Group Registration">
        Group Registration has not started yet. It will begin shortly! Thank you for your patience.
      </Tab>
      {/* <Tab eventKey="verification" title="Registration Verification">
      Registration Verification facility will be available shortly.
      </Tab> */}
    </Tabs>
    </Container>
    <Footer />
    </>
  )
}

export default Register
import React from 'react'
import NavBar from '../../components/common/NavBar'
import Footer from '../../components/common/Footer'
import './Timelines.scss';
import PageHeading from '../../components/pageHeading/PageHeading';
import bg from '../../assets/images/time.jpg';
import { Container, Row , Col} from 'react-bootstrap';
import { Helmet } from 'react-helmet';

const Timelines = () => {
  return (
    <>
    <Helmet>
                <meta charSet="utf-8" />
                <title>Timelines - HSR HackSprint</title>
                <meta name="title" content="HSR HackSprint Timelines - Key Dates and Deadlines for the Annual Hackathon" />
                <meta  name="description" content="Stay informed about the important dates and deadlines for HSR HackSprint, the annual hackathon for engineering students. Mark your calendars and never miss a crucial milestone in this exciting event, from registration opening to project submission deadlines."/>
                <link rel="canonical" href="https://hacksprint.hsrsolutions.co.in/hackathon-details" />
            </Helmet>
    <NavBar />
    <PageHeading bg={bg} title={"Timelines"} oneliner={"Mark your calendars and stay tuned for the exciting timelines"} />
    <Container className='mt-5 mb-5 hsr-timeline-area'>
        <Row className='hsr-time-head mb-5'>
            <Col md={4} lg={4} sm={12} className='hsr-active'>
            <span className='d-block hsr-day'>Day 0</span><span className='hsr-date'>July 30, 2023</span>
            </Col>
            <Col md={8} lg={8} sm={12} className='hsr-normal'></Col>
            <Col md={12} lg={12} sm={12}>
            <div className='hsr-time-details px-4'>
                <p className='mb-4'><b>Ringing Bell Session: HSR HackSprint 1.0</b></p>
                <p>Get ready for the Ringing Bell Event of HSR Hi-Tech Solutions Flagship Hackathon. Grab the opportunity to meet leaders from HSR Hi-Tech Solutions and hear from them on how to ace the HackSprint and much more.</p>
                <p><b>Start Time:</b> 11:30 AM</p>
                <p><b>End Time:</b> 12:45 PM</p>

                <p className='mt-5'><b>Demo Quiz</b></p>
                <p>Participants are recommended to undergo a Demo quiz round to make themselves familiar with the platform. This is NOT an elimination or evaluation round.</p>
                <p><b>Start Time:</b> 05:00 PM</p>
                <p><b>End Time:</b> 11:00 PM</p>
            </div>
            </Col>
        </Row>

        <Row className='hsr-time-head mb-5'>
            <Col md={4} lg={4} sm={12} className='hsr-active'>
            <span className='d-block hsr-day'>Day 1</span><span className='hsr-date'>July 31, 2023</span>
            </Col>
            <Col md={8} lg={8} sm={12} className='hsr-normal'></Col>
            <Col md={12} lg={12} sm={12}>
            <div className='hsr-time-details px-4'>
                <p className='mb-4'><b>Level 1: Website Development & Tech Quiz</b></p>
                <p>The Quiz will take place on different slots. Participants will receive email about their respective quiz date and time slot. Every participant will get a set of questions to be answered within 30 minutes. A team will be scored based on the average marks of all team members attempting the quiz round. Please note that it is mandatory for all team members to take the quiz. The quiz will be based on Website Development and Tech (Technology Awareness + Techstack & Computer Science Fundamentals).</p>
                <p><b>Start Time:</b> 11:00 AM</p>

                <p className='mt-5'><b>Problem Statement Preference Selection</b></p>
                <p>All the participated teams in Level 1 have to submit their preferences for the problem statements. The teams will be allocated the problem statements as per the preferences shared.</p>
                <p><b>Start Time:</b> 06:00 PM</p>
                <p><b>End Time:</b> 11:00 PM</p>
            </div>
            </Col>
        </Row>

        <Row className='hsr-time-head mb-5'>
            <Col md={4} lg={4} sm={12} className='hsr-active'>
            <span className='d-block hsr-day'>Day 2</span><span className='hsr-date'>August 1, 2023</span>
            </Col>
            <Col md={8} lg={8} sm={12} className='hsr-normal'></Col>
            <Col md={12} lg={12} sm={12}>
            <div className='hsr-time-details px-4'>
                <p className='mb-4'><b>Level 2: Submission Round</b></p>
                <p>All teams from Level 1 will work on the Idea Submission and will develop an Idea Brief along with a video. They will be able to make submissions until the deadline for this round anytime during the challenge window.</p>
                {/* <p><b>Start Time:</b> 11:30 AM</p>
                <p><b>End Time:</b> 12:45 PM</p> */}
            </div>
            </Col>
        </Row>
        <Row className='hsr-time-head mb-5'>
            <Col md={4} lg={4} sm={12} className='hsr-active'>
            <span className='d-block hsr-day'>Day 3</span><span className='hsr-date'>August 2, 2023</span>
            </Col>
            <Col md={8} lg={8} sm={12} className='hsr-normal'></Col>
            <Col md={12} lg={12} sm={12}>
            <div className='hsr-time-details px-4'>
                <p className='mb-4'><b>War Day</b></p>
                <p>Work on the Idea Submission and develop an Idea Brief along with a video and product.</p>
            </div>
            </Col>
        </Row>

        <Row className='hsr-time-head mb-5'>
            <Col md={4} lg={4} sm={12} className='hsr-active'>
            <span className='d-block hsr-day'>Day 4</span><span className='hsr-date'>August 3, 2023</span>
            </Col>
            <Col md={8} lg={8} sm={12} className='hsr-normal'></Col>
            <Col md={12} lg={12} sm={12}>
            <div className='hsr-time-details px-4'>
                <p className='mb-4'><b>War Day</b></p>
                <p>Work on the Idea Submission and develop an Idea Brief along with a video and product.</p>
            </div>
            </Col>
        </Row>

        <Row className='hsr-time-head mb-5'>
            <Col md={4} lg={4} sm={12} className='hsr-active'>
            <span className='d-block hsr-day'>Day 5</span><span className='hsr-date'>August 4, 2023</span>
            </Col>
            <Col md={8} lg={8} sm={12} className='hsr-normal'></Col>
            <Col md={12} lg={12} sm={12}>
            <div className='hsr-time-details px-4'>
                <p className='mb-4'><b>War Day</b></p>
                <p>Work on the Idea Submission and develop an Idea Brief along with a video and product.</p>
            </div>
            </Col>
        </Row>

        <Row className='hsr-time-head mb-5'>
            <Col md={4} lg={4} sm={12} className='hsr-active'>
            <span className='d-block hsr-day'>Day 6</span><span className='hsr-date'>August 5, 2023</span>
            </Col>
            <Col md={8} lg={8} sm={12} className='hsr-normal'></Col>
            <Col md={12} lg={12} sm={12}>
            <div className='hsr-time-details px-4'>
                <p className='mb-4'><b>War Day</b></p>
                <p>Work on the Idea Submission and develop an Idea Brief along with a video and product.</p>
            </div>
            </Col>
        </Row>

        <Row className='hsr-time-head mb-5'>
            <Col md={4} lg={4} sm={12} className='hsr-active'>
            <span className='d-block hsr-day'>Day 7</span><span className='hsr-date'>August 6, 2023</span>
            </Col>
            <Col md={8} lg={8} sm={12} className='hsr-normal'></Col>
            <Col md={12} lg={12} sm={12}>
            <div className='hsr-time-details px-4'>
                <p className='mb-4'><b>War Day</b></p>
                <p>Work on the Idea Submission and develop an Idea Brief along with a video and product.</p>
            </div>
            </Col>
        </Row>

        <Row className='hsr-time-head mb-5'>
            <Col md={4} lg={4} sm={12} className='hsr-active'>
            <span className='d-block hsr-day'>Day 8</span><span className='hsr-date'>August 7, 2023</span>
            </Col>
            <Col md={8} lg={8} sm={12} className='hsr-normal'></Col>
            <Col md={12} lg={12} sm={12}>
            <div className='hsr-time-details px-4'>
                <p className='mb-4'><b>War Day</b></p>
                <p>Work on the Idea Submission and develop an Idea Brief along with a video and product.</p>
            </div>
            </Col>
        </Row>

        <Row className='hsr-time-head mb-5'>
            <Col md={4} lg={4} sm={12} className='hsr-active'>
            <span className='d-block hsr-day'>Day 9</span><span className='hsr-date'>August 8, 2023</span>
            </Col>
            <Col md={8} lg={8} sm={12} className='hsr-normal'></Col>
            <Col md={12} lg={12} sm={12}>
            <div className='hsr-time-details px-4'>
                <p className='mb-4'><b>War Day</b></p>
                <p>Work on the Idea Submission and develop an Idea Brief along with a video and product.</p>
            </div>
            </Col>
        </Row>

        <Row className='hsr-time-head mb-5'>
            <Col md={4} lg={4} sm={12} className='hsr-active'>
            <span className='d-block hsr-day'>Day 10</span><span className='hsr-date'>August 9, 2023</span>
            </Col>
            <Col md={8} lg={8} sm={12} className='hsr-normal'></Col>
            <Col md={12} lg={12} sm={12}>
            <div className='hsr-time-details px-4'>
                <p className='mb-4'><b>War Day</b></p>
                <p>Work on the Idea Submission and develop an Idea Brief along with a video and product.</p>
            </div>
            </Col>
        </Row>

        <Row className='hsr-time-head mb-5'>
            <Col md={4} lg={4} sm={12} className='hsr-active'>
            <span className='d-block hsr-day'>Day 11</span><span className='hsr-date'>August 10, 2023</span>
            </Col>
            <Col md={8} lg={8} sm={12} className='hsr-normal'></Col>
            <Col md={12} lg={12} sm={12}>
            <div className='hsr-time-details px-4'>
                <p className='mb-4'><b>War Day</b></p>
                <p>Work on the Idea Submission and develop an Idea Brief along with a video and product.</p>
            </div>
            </Col>
        </Row>

        <Row className='hsr-time-head mb-5'>
            <Col md={4} lg={4} sm={12} className='hsr-active'>
            <span className='d-block hsr-day'>Day 12</span><span className='hsr-date'>August 11, 2023</span>
            </Col>
            <Col md={8} lg={8} sm={12} className='hsr-normal'></Col>
            <Col md={12} lg={12} sm={12}>
            <div className='hsr-time-details px-4'>
                <p className='mb-4'><b>War Day</b></p>
                <p>Work on the Idea Submission and develop an Idea Brief along with a video and product.</p>
            </div>
            </Col>
        </Row>

        <Row className='hsr-time-head mb-5'>
            <Col md={4} lg={4} sm={12} className='hsr-active'>
            <span className='d-block hsr-day'>Day 13</span><span className='hsr-date'>August 12, 2023</span>
            </Col>
            <Col md={8} lg={8} sm={12} className='hsr-normal'></Col>
            <Col md={12} lg={12} sm={12}>
            <div className='hsr-time-details px-4'>
                <p className='mb-4'><b>War Day</b></p>
                <p>Work on the Idea Submission and develop an Idea Brief along with a video and product.</p>
            </div>
            </Col>
        </Row>

        <Row className='hsr-time-head mb-5'>
            <Col md={4} lg={4} sm={12} className='hsr-active'>
            <span className='d-block hsr-day'>Day 14</span><span className='hsr-date'>August 13, 2023</span>
            </Col>
            <Col md={8} lg={8} sm={12} className='hsr-normal'></Col>
            <Col md={12} lg={12} sm={12}>
            <div className='hsr-time-details px-4'>
                <p className='mb-4'><b>Submission Day</b></p>
                <p>Submit your ideas, video and code for the evaluation before 06:00 PM.</p>
            </div>
            </Col>
        </Row>

        


        <Row className='hsr-time-head mb-5'>
            <Col md={4} lg={4} sm={12} className='hsr-active'>
            <span className='d-block hsr-day'>Day 15</span><span className='hsr-date'>August 14, 2023</span>
            </Col>
            <Col md={8} lg={8} sm={12} className='hsr-normal'></Col>
            <Col md={12} lg={12} sm={12}>
            <div className='hsr-time-details px-4'>
                <p className='mb-4'><b>Level 3: The Finale</b></p>
                <p>In the final level of the hackathon, finalists will be invited to showcase their solutions to the panel of domain experts at judges.</p>
                <p><b>Start Time:</b> 11:00 AM</p>
                <p><b>End Time:</b> 06:00 PM</p>
            </div>
            </Col>
        </Row>
    </Container>
    <Footer />
    </>

  )
}

export default Timelines
import React from 'react'
import { Container, Accordion, Row, Col } from 'react-bootstrap'
import { PlusSquareFill, DashSquareFill } from 'react-bootstrap-icons'

const FAQ = () => {
  return (
    <>
    <Container className='mt-5 mb-5 pb-5 hsr-faqs'>
        <h1>FAQ</h1>
       <Row>
        <Col md={6} lg={6} sm={12}>
        <Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header><PlusSquareFill className='me-3 hsr-plus' /><DashSquareFill className='me-3 hsr-minus' /> What is HSR HackSprint?</Accordion.Header>
        <Accordion.Body>
        HSR HackSprint is a hackathon for engineering students to come together and collaborate on innovative solutions to real-world problems. The hackathon will be held on July 31 - August 13, 2023(online).
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header><PlusSquareFill className='me-3 hsr-plus' /><DashSquareFill className='me-3 hsr-minus' /> Who can participate in HSR HackSprint?</Accordion.Header>
        <Accordion.Body>
        HSR HackSprint is open to students pursuing B.Tech/B.E./B.S./M.Tech/M.S or related Engineering Programs across All Engineering Colleges in India (Batch 2024, 2025, 2026 & 2027).
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header><PlusSquareFill className='me-3 hsr-plus' /><DashSquareFill className='me-3 hsr-minus' /> What are the eligibility criteria for HSR HackSprint?</Accordion.Header>
        <Accordion.Body>
        To be eligible to participate in HSR HackSprint, you must be an engineering student and have a valid college ID. Students must have a team of 3 to 5 members. They should be full-time students from the same/other engineering college.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header><PlusSquareFill className='me-3 hsr-plus' /><DashSquareFill className='me-3 hsr-minus' /> What are the rules and regulations for HSR HackSprint?</Accordion.Header>
        <Accordion.Body>
        The rules and regulations for HSR HackSprint is available on Event Details section. Go through all the things carefully.
        </Accordion.Body>
      </Accordion.Item>
      </Accordion>
        </Col>
        <Col md={6} lg={6} sm={12}>
        <Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header><PlusSquareFill className='me-3 hsr-plus' /><DashSquareFill className='me-3 hsr-minus' /> What are the problem statements for HSR HackSprint?</Accordion.Header>
        <Accordion.Body>
        The problem statements for HSR HackSprint will be announced on the website closer to the event. Timelines is mentioned for all the activities on timelines section.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header><PlusSquareFill className='me-3 hsr-plus' /><DashSquareFill className='me-3 hsr-minus' /> What are the prizes for HSR HackSprint?</Accordion.Header>
        <Accordion.Body>
        The winner and first runner up teams will receive cash prizes, as well as the opportunity to work with HSR Hi-Tech Solutions on their projects(subject to performance). Top 3 teams will get goodies from HSR Hi-Tech Solutions.
        </Accordion.Body>
      </Accordion.Item>
    <Accordion.Item eventKey="2">
        <Accordion.Header><PlusSquareFill className='me-3 hsr-plus' /><DashSquareFill className='me-3 hsr-minus' /> How do I apply for HSR HackSprint?</Accordion.Header>
        <Accordion.Body>
        To apply for HSR HackSprint, visit the website and submit your individual application first and complete the payment of ₹ 199. After successful individual registration, you can register your group by using registration number of your team members which you will get via email.  The application deadline is July 25, 2023.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header><PlusSquareFill className='me-3 hsr-plus' /><DashSquareFill className='me-3 hsr-minus' /> What are the payment options available for registration?</Accordion.Header>
        <Accordion.Body>
        You will get all types of payments option in registration such as Credit Card, Debit Card, Internet Banking & UPI. 
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
        </Col>
       </Row>
    </Container>
    </>
  )
}

export default FAQ
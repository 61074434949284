import React from 'react'
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';

const TimelineTitle = (props) =>{
    return (
        <><span className='d-block hsr-day'>{props.day}</span><span className='hsr-date'>{props.date}</span></>
    );
}

const TimeLines = () => {
    return (
        <>
            <Container className='p-3 mt-5 mb-5 hsr-timelines'>
                <Row className='p-3 mb-4'>
                    <Col md={3} lg={3} sm={12} className='mb-3'>
                        <h3>Timelines</h3>

                    </Col>
                    <Col md={6} lg={6} sm={12}  className='mb-3' >
                        <p>This hackathon run through all 15 days from July 31 - August 2023.
                            We also provide free lunch and coffee break in each day.</p>
                    </Col>
                    <Col md={3} lg={3} sm={12}  className='mb-3'>
                        <a href='/timelines' className='ms-auto d-block'><i>View Full Program</i></a>
                    </Col>
                </Row>
                <Tabs
                    defaultActiveKey="day1"
                    transition={true}
                    fill
                >
                    <Tab eventKey="day1" title={<TimelineTitle day={"Day 0"} date={"July 30, 2023"} />}>
                    <p className='mb-4'><b>Ringing Bell Session: HSR HackSprint 1.0</b></p>
                <p>Get ready for the Ringing Bell Event of HSR Hi-Tech Solutions Flagship Hackathon. Grab the opportunity to meet leaders from HSR Hi-Tech Solutions and hear from them on how to ace the HackSprint and much more.</p>
                <p><b>Start Time:</b> 11:30 AM</p>
                <p><b>End Time:</b> 12:45 PM</p>

                <p className='mt-5'><b>Demo Quiz</b></p>
                <p>Participants are recommended to undergo a Demo quiz round to make themselves familiar with the platform. This is NOT an elimination or evaluation round.</p>
                <p><b>Start Time:</b> 05:00 PM</p>
                <p><b>End Time:</b> 11:00 PM</p>
                    </Tab>
                    <Tab eventKey="day2" title={<TimelineTitle day={"Day 1"} date={"July 31, 2023"} />}>
                    <p className='mb-4'><b>Level 1: Website Development & Tech Quiz</b></p>
                <p>The Quiz will take place on different slots. Participants will receive email about their respective quiz date and time slot. Every participant will get a set of questions to be answered within 30 minutes. A team will be scored based on the average marks of all team members attempting the quiz round. Please note that it is mandatory for all team members to take the quiz. The quiz will be based on Website Development and Tech (Technology Awareness + Techstack & Computer Science Fundamentals).</p>
                <p><b>Start Time:</b> 11:00 AM</p>

                <p className='mt-5'><b>Problem Statement Preference Selection</b></p>
                <p>All the participated teams in Level 1 have to submit their preferences for the problem statements. The teams will be allocated the problem statements as per the preferences shared.</p>
                <p><b>Start Time:</b> 06:00 PM</p>
                <p><b>End Time:</b> 11:00 PM</p>
                    </Tab>
                    <Tab eventKey="day3" title={<TimelineTitle day={"Day 2"} date={"August 1, 2023"} />}>
                        <p><b>War Day</b></p>
                        <p>Work on the Idea Submission and develop an Idea Brief along with a video and product.</p>
                    </Tab>
                    <Tab eventKey="day4" title={<TimelineTitle day={"Day 3"} date={"August 2, 2023"} />}>
                    <p><b>War Day</b></p>
                        <p>Work on the Idea Submission and develop an Idea Brief along with a video and product.</p>
                    </Tab>
                    <Tab eventKey="day5" title={<TimelineTitle day={"Day 4"} date={"August 3, 2023"} />}>
                    <p><b>War Day</b></p>
                        <p>Work on the Idea Submission and develop an Idea Brief along with a video and product.</p>
                    </Tab>
                    <Tab eventKey="day6" title={<TimelineTitle day={"Day 5"} date={"August 4, 2023"} />}>
                    <p><b>War Day</b></p>
                        <p>Work on the Idea Submission and develop an Idea Brief along with a video and product.</p>
                    </Tab>
                </Tabs>
            </Container>
        </>
    )
}

export default TimeLines
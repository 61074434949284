import React from 'react'
import { Container } from 'react-bootstrap'
import NavBar from '../../components/common/NavBar';
import Footer from '../../components/common/Footer';
import PageHeading from '../../components/pageHeading/PageHeading';
import bg from '../../assets/images/term.jpg';
import { Helmet } from 'react-helmet';

const Terms = () => {
  return (
    <>
    <Helmet>
                <meta charSet="utf-8" />
                <title>Terms & Conditions - HSR HackSprint</title>
                <meta name="title" content="Terms and Conditions | HSR HackSprint | Engineering Student Hackathon" />
                <meta  name="description" content="Read and agree to the Terms and Conditions of HSR HackSprint, the premier engineering student hackathon. Learn about eligibility, code of conduct, intellectual property, judging, and more. Participate responsibly and showcase your talent in this exciting event."/>
                <link rel="canonical" href="https://hacksprint.hsrsolutions.co.in/hackathon-details" />
            </Helmet>
    <NavBar/>
    <PageHeading bg={bg} title={"Terms & Conditions"} oneliner="Terms & Conditions for HSR HackSprint" />
      <Container className='mt-5 mb-5'>
        <p>Please read these Terms and Conditions carefully before participating in HSR HackSprint. By registering and participating in the hackathon, you agree to comply with these terms and conditions.</p>
        <ol>
          <li><b>Eligibility:</b></li>

          <ul>
            <li>HSR HackSprint is open to engineering students currently enrolled in a recognized educational institution in India.</li>
            <li>Participants must comply with the age restrictions set by their educational institution and local laws.</li>
          </ul>
          <br></br>
          <li><b>Team Formation:</b></li>
          <ul>
            <li>Participants may form teams with a maximum number of 5 members and at least 3 members as specified in the hackathon guidelines.</li>
            <li>Each team must have a designated team leader responsible for communication and coordination with the organizers.</li>
          </ul>
          <br></br>
          <li><b>Code of Conduct:</b></li>

          <ul>
            <li>Participants are expected to behave in a professional and respectful manner throughout the hackathon.</li>
            <li>Harassment, discrimination, or any form of offensive behavior will not be tolerated.</li>
            <li>Participants must comply with the rules and guidelines set by the hackathon organizers.</li>
          </ul>
          <br></br>
          <li><b>Intellectual Property:</b></li>

          <ul>
            <li>Participants retain ownership of the intellectual property rights of their projects.</li>
            <li>
              By participating in HSR HackSprint, participants grant the organizers a non-exclusive license to showcase and promote their projects for promotional and educational purposes.</li>
          </ul>
          <br></br>
          <li><b>Project Submission:</b></li>

          <ul>
            <li>All project submissions must adhere to the guidelines and deadlines specified by the hackathon organizers.</li>
            <li>Projects must be original and not infringe upon any third-party rights.</li>
          </ul>
          <br></br>
          <li><b>Judging and Awards:</b></li>

          <ul>
            <li>Projects will be evaluated based on criteria determined by the hackathon organizers.</li>
            <li>The decisions of the judges regarding the winners and awards are final and binding.</li>
          </ul>
          <br></br>
          <li><b>Privacy:</b></li>

          <ul>
            <li> By participating in HSR HackSprint, participants acknowledge and agree to the collection, use, and handling of their personal information as outlined in the Privacy Policy.</li>
          </ul>
          <br></br>
          <li><b>Limitation of Liability:</b></li>

          <ul>
            <li>HSR HackSprint organizers, sponsors, and affiliates shall not be held liable for any damages, losses, or injuries arising out of participation in the hackathon.</li>
          </ul>
          <br></br>
          <li><b>Modifications and Termination:</b></li>

          <ul>
            <li>The hackathon organizers reserve the right to modify or terminate HSR HackSprint at their discretion.</li>
            <li>In the event of modification or termination, participants will be notified through the provided contact information.</li>
          </ul>
          <br></br>
          <li><b>Governing Law and Jurisdiction:</b></li>

          <ul>
            <li>These Terms and Conditions shall be governed by and construed in accordance with the laws of India. Any disputes arising out of or in connection with these terms shall be subject to the exclusive jurisdiction of the courts of India.</li>
          </ul>
          <br></br>
        </ol>
        <p>This event will be conducted only when there is minimum participation of 30 teams. Any change in date and time of the event will be informed to the participants via their registered email.
        If you have any questions or concerns regarding these Terms and Conditions, please contact us at <a href="mailto:info@hsrsolutions.co.in" target='_blank' rel='noreferrer'>info@hsrsolutions.co.in</a>. By participating in HSR HackSprint, you acknowledge that you have read, understood, and agreed to these Terms and Conditions.</p>

        <b>Last updated:</b> July 14, 2023
      </Container>
      <Footer />
    </>
  )
}

export default Terms
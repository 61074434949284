import React from 'react';
import bg from '../../assets/images/bg3.jpg';
import { Container, Breadcrumb } from 'react-bootstrap';
import Button from '../../components/button/Button';

const HeroImage = () => {
    const heroImageStyle = {
        width: '100%',
    height: '90vh',
    backgroundImage: `url(${bg})`,
    backgroundSize: 'cover'  
    }
  return (
    <>
    <Container fluid className='hsr-hero-image' style={heroImageStyle}>
        <div className='hsr-overlay'>
            <Container className='hsr-caption'>
            <div>
            <h1 className='hsr-first-title'>The Coder</h1>
            <h1 className='hsr-second-title'>#Hackathon</h1>
            <Breadcrumb className='mb-lg-5 mb-4'>
      <Breadcrumb.Item >31<sup>st</sup> July - 14<sup>th</sup> August 2023</Breadcrumb.Item>
      <Breadcrumb.Item >
        Haldia
      </Breadcrumb.Item>
    </Breadcrumb>
    <Button title={"Register Online"} action={()=> window.location.href='/register'}  />
            </div>
            </Container>
        </div>
    </Container>
    </>
  )
}

export default HeroImage
import React from 'react'
import NavBar from '../../components/common/NavBar'
import Footer from '../../components/common/Footer'
import { Container } from 'react-bootstrap'
import PageHeading from '../../components/pageHeading/PageHeading'
import bg from '../../assets/images/privacy.jpg';
import { Helmet } from 'react-helmet'

const Privacy = () => {
  return (
    <>
    <Helmet>
                <meta charSet="utf-8" />
                <title>Privacy Policy - HSR HackSprint</title>
                <meta name="title" content="HSR HackSprint Privacy Policy: Protecting Your Personal Information" />
                <meta  name="description" content="Read our Privacy Policy to understand how we collect, use, and safeguard your personal information at HSR HackSprint. We value your privacy and are committed to ensuring the security of your data during your participation in our hackathon event."/>
                <link rel="canonical" href="https://hacksprint.hsrsolutions.co.in/privacy-policy" />
            </Helmet>
    <NavBar />
    <PageHeading bg={bg} title="Privacy Policy" oneliner="Privacy Policy for HSR HackSprint, Your data is safe with us." />
    <Container className='mt-5 mb-5'>
        <p>
    At HSR HackSprint, we value your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, and safeguard the data you provide to us during your participation in our hackathon event. Please read this policy carefully to understand our practices and how we handle your information.</p>
<br></br>
<ol>
<li><b>Information We Collect:</b></li>

<ul>
<li><b>Personal Information:</b> When you register for HSR HackSprint, we may collect certain personal information such as your name, email address, contact number, and educational institution details.</li>
<li><b>Team Information: </b>If you participate as part of a team, we may collect information about your team members, including their names and contact information.</li>

<li><b>Event Participation: </b>We may collect information regarding your participation in HSR HackSprint, including project details, submissions, and presentations.</li>
</ul><br></br>
<li><b>Use of Information:</b></li>

<ul><li><b>Internal Use:</b> We use the collected information to administer and manage HSR HackSprint, including registration, communication, and event logistics.</li>
<li><b>Communication: </b>We may use your contact information to send you updates, notifications, and important announcements related to the hackathon.</li>
<li><b>Evaluation and Judging:</b> The information you provide may be used for project evaluation, judging purposes, and the determination of awards and prizes.</li>
<li><b>Statistical Analysis:</b> We may aggregate and anonymize the data collected to perform statistical analysis, improve our services, and understand participant demographics.</li></ul>
<br></br>
<li><b>Data Sharing:</b></li>

<ul>
    <li>
    <b>Third Parties:</b> We may engage third-party service providers to assist us in organizing and managing HSR HackSprint. These providers will have access to the necessary information only for the purposes of assisting us and are obligated to maintain confidentiality.</li>
    <li><b>Legal Requirements:</b> We may disclose your information if required to do so by law or in response to a valid legal request.</li>
</ul>
<br></br>
<li><b>Data Security:</b></li>

<ul>
    <li>We implement reasonable security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction.</li>
    <li>While we strive to maintain the security of your data, please note that no method of transmission or electronic storage is completely secure. We cannot guarantee absolute security of your information.</li>
</ul>
<br></br>
<li><b>Data Retention:</b></li>
<ul>
    <li>We retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy and comply with applicable laws and regulations.</li>
</ul>
<br></br>
<li><b>Your Rights:</b></li>

<ul>
    <li>You have the right to access, update, correct, or delete your personal information held by us. To exercise these rights, please contact us using the provided contact information.</li>
</ul>
<br></br>
<li><b>Changes to Privacy Policy:</b></li>
<ul>
    <li>We may update this Privacy Policy from time to time. Any changes will be effective upon posting the revised policy on our website. We encourage you to review this policy periodically for any updates.</li>
</ul>
</ol><br></br>
<p>If you have any questions or concerns about our Privacy Policy or the handling of your personal information, please contact us at [contact email/phone number]. By participating in HSR HackSprint, you acknowledge that you have read, understood, and agreed to this Privacy Policy.</p>

<b>Last updated:</b> July 14, 2023.
    </Container>
    <Footer />
    </>
  )
}

export default Privacy
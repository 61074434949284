import React from 'react'
import { Container } from 'react-bootstrap'

const About = () => {
  return (
    <>
    <Container fluid className='hsr-about'><div className='hsr-overlay'>
        <Container className=' py-5'>
        <h1>About HSR HackSprint</h1>
        <p className='mt-5'>HSR HackSprint is a flagship hackathon by HSR Hi-Tech Solutions in association with Department of Computer Science & Engineering, Haldia Institute of Technology. It's the perfect opportunity for engineering students to get real-world experience, build a team, and put their skills to the test.<br></br><br></br>

We're here to help you turn your ideas into reality.<br></br><br></br>

If you're an engineering student, you already have the drive and determination that it takes to get things done—but we also know that sometimes it can be hard for new students to find their place in a team environment. That's why we're here: to give you the ability to work with experienced developers on real projects, develop presentations skills, and learn how to work as part of a team.</p>
<a className='d-block mt-5' href='/about'><i>View About HSR HackSprint</i></a>
        </Container>
        </div> </Container>
    </>
  )
}

export default About